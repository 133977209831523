<template>
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
  >
    <path
      d="M5.60714 1.19292C5.99767 1.58344 5.99767 2.21661 5.60714 2.60713C2.09767 6.11661 2.09767 11.8834 5.60714 15.3929C5.99767 15.7834 5.99767 16.4166 5.60714 16.8071C5.21662 17.1977 4.58345 17.1977 4.19293 16.8071C-0.0975945 12.5166 -0.0975945 5.48344 4.19293 1.19292C4.58345 0.802393 5.21662 0.802393 5.60714 1.19292Z"
    />
    <path
      d="M8.49448 3.9805C8.89187 4.36404 8.9031 4.9971 8.51957 5.39449C7.72538 6.21738 7.2283 7.28162 7.10701 8.41879C6.98571 9.55596 7.24712 10.7011 7.84986 11.673C8.14094 12.1423 7.99643 12.7588 7.52708 13.0499C7.05772 13.341 6.44127 13.1964 6.15019 12.7271C5.31292 11.377 4.94979 9.78631 5.11829 8.20666C5.28678 6.62701 5.97728 5.14867 7.08049 4.00559C7.46402 3.6082 8.09708 3.59697 8.49448 3.9805Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 6.00003C10.3432 6.00003 9.00003 7.34318 9.00003 9.00003C9.00003 10.1843 9.68628 11.2084 10.6828 11.6961L7.06023 21.6583C6.8715 22.1773 7.13925 22.7511 7.65829 22.9398C8.17732 23.1286 8.75108 22.8608 8.93982 22.3418L10.155 19H13.8451L15.0602 22.3418C15.249 22.8608 15.8227 23.1286 16.3418 22.9398C16.8608 22.7511 17.1286 22.1773 16.9398 21.6583L13.3172 11.6961C14.3138 11.2084 15 10.1843 15 9.00003C15 7.34318 13.6569 6.00003 12 6.00003ZM11 9.00003C11 8.44775 11.4477 8.00003 12 8.00003C12.5523 8.00003 13 8.44775 13 9.00003C13 9.55231 12.5523 10 12 10C11.4477 10 11 9.55231 11 9.00003ZM13.1178 17L12 13.9262L10.8823 17H13.1178Z"
    />
    <path
      d="M15.4929 4.09291C15.8835 3.70239 16.5166 3.70239 16.9071 4.09291C19.2541 6.43982 19.5402 10.0647 17.8505 12.7961C17.5599 13.2658 16.9436 13.411 16.4739 13.1204C16.0043 12.8299 15.8591 12.2136 16.1496 11.7439C17.3799 9.75533 17.146 7.16022 15.4929 5.50712C15.1024 5.1166 15.1024 4.48344 15.4929 4.09291Z"
    />
    <path
      d="M19.8064 1.19219C19.4155 0.802069 18.7823 0.802719 18.3922 1.19364C18.0021 1.58457 18.0027 2.21773 18.3937 2.60786C19.2276 3.44011 19.8892 4.42866 20.3407 5.51694C20.7921 6.60521 21.0245 7.77183 21.0245 8.95002C21.0245 10.1282 20.7921 11.2948 20.3407 12.3831C19.8892 13.4714 19.2276 14.4599 18.3937 15.2922C18.0027 15.6823 18.0021 16.3155 18.3922 16.7064C18.7823 17.0973 19.4155 17.098 19.8064 16.7079C20.8265 15.6898 21.6358 14.4806 22.188 13.1494C22.7402 11.8182 23.0245 10.3912 23.0245 8.95002C23.0245 7.50885 22.7402 6.08182 22.188 4.75062C21.6358 3.41943 20.8265 2.21021 19.8064 1.19219Z"
    />
  </svg>
</template>
